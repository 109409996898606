import {isFunction} from "@utils/utils";

export default {
    props:{
        HOT_KEYS:{
            type:Array,
            default(){
                return [];
            }
        },
    },
    data(){
        return {
            activate: true,
            DEFAULT_HOT_KEYS:{
                "F4":this.searchDefault,
                "F6":this.submitFormDefault
            }
        };
    },
    activated(){
        this.activate = true;
    },
    deactivated(){
        this.activate = false;
    },
    methods:{
        searchDefault(){
            this.$message("当前页面不支持搜索操作!");
        },
        submitFormDefault(){
            this.$message("当前页面不支持提交操作!");
        },
        handleHotKeyOnActivate(e, key){
            if(this.activate){
                if(this.$listeners.hasOwnProperty(key)){
                    const listener =this.$listeners[key];
                    if(isFunction(listener)){
                        listener();
                    }
                }
            }
        }
    },
    computed: {
        /**
         * 自动化装载默认快捷键跟自定义快捷键
         */
        installationKeymap () {
            const keyMap= {};
            Object.keys(this.DEFAULT_HOT_KEYS).forEach(key=>{
                if(this.$listeners.hasOwnProperty(key)){
                    keyMap[key] = (e)=>{
                        this.handleHotKeyOnActivate(e, key);
                    };
                }
            });
            this.HOT_KEYS.forEach((key)=>{
                if(this.$listeners.hasOwnProperty(key)){
                    keyMap[key] = (e)=>{
                        this.handleHotKeyOnActivate(e, key);
                    };
                }
            });
            return keyMap;
        }
    },
};