<template>
    <div class="component-page height-100">
        <el-scrollbar v-if="autoScroll" class="height-100">
            <slot> </slot>
        </el-scrollbar>
        <slot v-else></slot>
    </div>
</template>

<script>
// v-hotkey="installationKeymap"
import HotKey from "@/hotkey";
export default {
    name: "page",
    extends: HotKey,
    props: {
        autoScroll: {
            type: Boolean,
            default: true
        }
    }
};
</script>
<style scoped lang="scss">
// .component-page {
// }
</style>
